import React from 'react';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { default as PdpFooterRaw } from '@cof/graffiti-alley-spray-cans/organisms/PdpFooter';
import { getNavLinks } from '../../../lib/utils';
import { useConfig } from '../../../hooks/useConfig';

const PdpFooter = ({ intl, hidePromo }) => {
  const config = useConfig(intl.locale);
  return (
    <PdpFooterRaw
      promoLink="https://creditkeeper.capitalone.ca/"
      nav={getNavLinks(intl, config.dapp)}
      hidePromo={hidePromo || intl.locale === 'fr'}
    />
  );
};

PdpFooter.propTypes = {
  intl: intlShape,
  hidePromo: PropTypes.bool,
};

PdpFooter.defaultProps = {
  hidePromo: false,
};

export default PdpFooter;
