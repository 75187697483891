import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import SEO from '../../components/SEO/SEO';
import Layout from '../../components/Layout';
import './credit-card-terms-and-conditions.scss';
import PdpFooter from '../../components/Pdp/PdpFooter';

const ApplicationTermsPage = injectIntl(({ intl }) => {
  const footer = () => {
    return <PdpFooter intl={intl} hidePromo={true} />;
  };

  return (
    <Layout pageName="application-terms-page" className="application-terms" renderFooter={footer}>
      <div className="application-terms-page-content-wrapper">
        <h1>
          <FormattedMessage id={`pages.about.application-terms.h1`} />
        </h1>
        <p>
          <FormattedMessage id={`pages.about.application-terms.p1`} />
        </p>
        <ul>
          {Array(9)
            .fill()
            .map((_, i) => {
              return (
                <li key={i}>
                  <FormattedMessage id={`pages.about.application-terms.li${i}`} />
                </li>
              );
            })}
        </ul>
      </div>
    </Layout>
  );
});

export default ApplicationTermsPage;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO titleKey="pages.about.application-terms.page-title" intl={pageContext.intl}>
    <meta name="robots" content="noindex" />
  </SEO>
);
/* eslint-enable react/prop-types */
